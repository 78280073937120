body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.logo {
  width: 50px;
  height: 31px;
  /* background: #333; */
  /* margin: 0 0 0 0; */
  float: left;
}

.site-layout-content {
  /* min-height: 800px; */
  padding: 24px;
  /* margin-top: 150px; */
  /* background: #fff; */
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.ant-input-affix-wrapper {
  width: 85%;
}
/* .ant-layout,
.ant-layout-footer {
  background-color: #dcdcdc !important;
} */
.ant-layout {
  background-color: #dcdcdc !important;
}
.ant-layout-footer {
  background-color: "#f0f0f0" !important;
}
#mapid {
  height: 600px;
}
.leaflet-container {
  margin-left: 2%;
  /* margin-right: 30px; */
  width: 96%;
  height: 85vh;
}
@media screen and (max-width: 577px) {
  .myprofile-div {
    min-width: 95% !important;
  }
  .myprofile-label {
    margin-left: 30vw;
    font-size: 25px;
  }
}

.ant-layout-footer {
  padding: 10px 50px !important;
}
/*
thead[class*="ant-table-thead"] th {
   background-color: #008b8b !important; 
  background-color: #badfe7 !important;
}
tbody[class*="ant-table-tbody"] tr,
td {
  background-color: #dcdce5 !important;
}

tbody[class*="ant-table-tbody"] tr:hover {
  background-color: white !important;
} 
.ant-table-row:hover td {
  background-color: whitesmoke !important;
}
 td.ant-table-column-sort {
  background: unset !important;
}
td.ant-table-column-sort:hover {
  background: unset !important;
} */
/* .ddd {
  background: #395554;
} */

/* calendar css*/
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.events a:hover {
  background: #c8c8c8;
  border-radius: 25px;
  padding: 5px 0 0 2px;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.ant-picker-cell {
  cursor: default !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  overflow-x: hidden;
}

/*transfer*/
@media screen and (max-width: 512px) {
  .ant-transfer {
    display: block !important;
  }
  .ant-transfer-operation .ant-btn .anticon {
    transform: rotate(90deg);
  }
}

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
